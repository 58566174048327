'use strict';

window.console || (window.console = {
	log: function log() {}
});
var FUNCTIONS = window.FUNCTIONS || null;
var cssua = window.cssua || null;

(function ($) {
	var htmlHasClass = function htmlHasClass(_class) {
		return $('html').hasClass(_class);
	};
	var siteName = '';
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '400';
	var arrowPrev = '<button type="button" class="slick-prev"><i class="icon icon-arrow_right"></i></button>';
	var arrowNext = '<button type="button" class="slick-next"><i class="icon icon-arrow_right"></i></button>';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.0\\.|192\\.168\\.11\\.|192\\.168\\.1\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos -= 60;
					} else {
						pos -= 90;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.alfi-footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var footerPos = $('.alfi-footer-copy').offset().top + $('.alfi-footer-copy').height();
				var thisScroll = $this.scrollTop();
				var pagetopPos = $this.height() + $('.alfi-footer-copy').height() + thisScroll;

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
				if (footerPos <= pagetopPos) {
					$pagetop.addClass('is-bottom');
				} else {
					$pagetop.removeClass('is-bottom');
				}
			});
		},
		headerMenu: function headerMenu() {
			var scrollTopNow = void 0;
			var fixedOnContainer = function fixedOnContainer() {
				scrollTopNow = $(window).scrollTop();
				$('.container').css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				$('body').addClass('is-fixed');
			};
			var fixedOffContainer = function fixedOffContainer() {
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass('is-fixed');
				$(window).scrollTop(scrollTopNow);
			};

			if (FUNCTIONS.va.window.width <= 1024) {
				$('.alfi-header-hamburger').on('click', function (e) {
					$(e.currentTarget).toggleClass(a);
					$('.alfi-header-list').toggleClass(a);
					if ($(e.currentTarget).hasClass(a)) {
						fixedOnContainer();
					} else {
						fixedOffContainer();
					}
				});

				$('.alfi-header-list-a._spMenu').on('click', function (e) {
					$(e.currentTarget).find('.__spSwitch').toggleClass(a);
					$(e.currentTarget).next('.alfi-header-list-item').stop().slideToggle(slideSpeed);
					return false;
				});
			} else {
				$('.alfi-header-list-a').on('mouseenter', function (e) {
					var target = $(e.currentTarget).attr('data-target');
					if (!target) {
						$('.alfi-header-overlay').removeClass(a);
						$('.alfi-header-list-item').removeClass(a);
					} else {
						$('.alfi-header-overlay').addClass(a);
						$('.alfi-header-list-item').each(function (idx, ele) {
							if ($(ele).attr('data-target') === target) {
								$(ele).addClass(a);
							} else {
								$(ele).removeClass(a);
							}
						});
					}
				});
				$('.alfi-header-list').on('mouseleave', function (e) {
					$(e.currentTarget).find('.alfi-header-list-item').removeClass(a);
					$('.alfi-header-overlay').removeClass(a);
				});
			}
		},
		idxMainVisSlider: function idxMainVisSlider() {
			if ($('.alfi-idx-mainVis .__slider').length) {
				$('.alfi-idx-mainVis .__slider').slick({
					infinite: true,
					speed: 1000,
					autoplay: true,
					autoplaySpeed: 4000,
					slidesToShow: 1,
					arrows: false,
					dots: true
				});
			}
		},
		prodMainVisSlider: function prodMainVisSlider() {
			if ($('.alfi-prodCat-mainVis .__slider').length) {
				$('.alfi-prodCat-mainVis .__slider').slick({
					infinite: true,
					speed: 1000,
					autoplay: true,
					autoplaySpeed: 3000,
					slidesToShow: 1,
					arrows: false,
					dots: false,
					fade: true
				});
			}
		},
		prodImg: function prodImg() {
			$('.alfi-prodDetail-prodImg .__main.__1').slick({
				infinite: false,
				speed: 400,
				autoplay: false,
				slidesToShow: 1,
				arrows: false,
				dots: false,
				fade: true,
				asNavFor: '.alfi-prodDetail-prodImg .__list.__1'
			});
			$('.alfi-prodDetail-prodImg .__list.__1').slick({
				infinite: false,
				speed: 400,
				autoplay: false,
				slidesToShow: 3,
				slidesToScroll: 3,
				arrows: false,
				dots: false,
				asNavFor: '.alfi-prodDetail-prodImg .__main.__1',
				focusOnSelect: true
			});
			$('.alfi-prodDetail-prodImg .__main.__2').slick({
				infinite: false,
				speed: 400,
				autoplay: false,
				slidesToShow: 1,
				arrows: false,
				dots: false,
				fade: true,
				asNavFor: '.alfi-prodDetail-prodImg .__list.__2'
			});
			$('.alfi-prodDetail-prodImg .__list.__2').slick({
				infinite: false,
				speed: 400,
				autoplay: false,
				slidesToShow: 3,
				slidesToScroll: 3,
				arrows: false,
				dots: false,
				asNavFor: '.alfi-prodDetail-prodImg .__main.__2',
				focusOnSelect: true
			});
			$(".alfi-prodDetail-prodImg .__main.__5").slick({
				infinite: false,
				speed: 400,
				autoplay: false,
				slidesToShow: 1,
				arrows: false,
				dots: false,
				fade: true,
				asNavFor: ".alfi-prodDetail-prodImg .__list.__5"
			});
			$(".alfi-prodDetail-prodImg .__list.__5").slick({
				infinite: false,
				speed: 400,
				autoplay: false,
				slidesToShow: 5,
				slidesToScroll: 5,
				arrows: false,
				dots: false,
				asNavFor: ".alfi-prodDetail-prodImg .__main.__5",
				focusOnSelect: true
			});

			var changeImg = function changeImg(_thisImg) {
				var index = $(_thisImg).attr('data-slick-index');
				$(_thisImg).parents('.__list').prev('.__main').slick('slickGoTo', index);
			};
			if (cssua.ua.desktop) {
				$('.alfi-prodDetail-prodImg .__list').find('.slick-slide').mouseenter(function (e) {
					changeImg(e.currentTarget);
				});
			} else {
				$('.alfi-prodDetail-prodImg .__list').find('.slick-slide').on('click', function (e) {
					changeImg(e.currentTarget);
				});
			}

			// slick起動後にeaseScroll
			var hash = location.hash;
			if (hash) {
				$(window).on('load', function () {
					$('html, body').css('display', 'none');

					setTimeout(function () {
						$('html, body').css('display', 'block');

						var _hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;

						if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
							pos -= 70;
						} else {
							pos -= 100;
						}
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 100);
				});
			}

			// // Imgサイズセット
			// $('.alfi-prodDetail-prodImg .__main').height($('.alfi-prodDetail-prodImg  .__main').width());
			// $(window).resize(() => {
			// 	$('.alfi-prodDetail-prodImg .__main').height($('.alfi-prodDetail-prodImg  .__main').width());
			// });

			// const changeImg = (_thisImg) => {
			// 	if (!$(_thisImg).hasClass(a)) {
			// 		$('.__listImg').each((idx, ele) => { $(ele).removeClass(a) });
			// 		$(_thisImg).addClass(a);

			// 		$('.alfi-prodDetail-prodImg').find('.__mainImg.is-visible').removeClass(v);
			// 		$('.alfi-prodDetail-prodImg').find('.__mainImg').eq($('.__listImg').index($(_thisImg))).addClass(v);
			// 	}
			// };
			// if (cssua.ua.desktop) {
			// 	$('.__listImg').mouseenter((e) => { changeImg(e.currentTarget); });
			// } else {
			// 	$('.__listImg').on('click', (e) => { changeImg(e.currentTarget); });
			// }
		},
		prodLineupSlider: function prodLineupSlider() {
			if ($('.alfi-prodDetail-lineup .__slider').length) {
				$('.alfi-prodDetail-lineup .__slider').slick({
					infinite: true,
					speed: 1000,
					autoplay: false,
					slidesToShow: 3,
					arrows: true,
					dots: false,
					prevArrow: arrowPrev,
					nextArrow: arrowNext,
					responsive: [{
						breakpoint: 769,
						settings: {
							slidesToShow: 2
						}
					}, {
						breakpoint: 420,
						settings: {
							slidesToShow: 1
						}
					}]
				});
			}
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.pageTop();
			_this.headerMenu();
			_this.idxMainVisSlider();
			_this.prodMainVisSlider();
			_this.prodImg();
			_this.prodLineupSlider();

			svg4everybody();
		}
	};

	$(function () {
		return FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript();
	});
})(window.jQuery);